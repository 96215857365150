<template>
    <div class="wrapper">
    <!-- header -->
    <!-- 大網展開menu: header-menu-sec 移除hidden -->
    <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
    <header>
      <div class="pagesize">
        <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
          <p class="header-time">更新時間：{{updateTime}}</p>
          <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on" >需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
        </div>
      </div>
    </header>
    <!-- container -->
    <div class="container">
      <div class="pagesize">
        <ul class="breadcrumbs">
          <li><a href="#">需量反應</a></li>
          <li><span>群組成員資料</span></li>
        </ul>
        <div class="area bg-white">
          <div class="padding03">
            <h2 class="boxtitle margin02">群組成員資料</h2>
            <!-- table -->
            <div class="tableframe">
              <table class="table-rwd">
                <tr class="tr-dark table-rwd-th">
                  <th>機關名稱</th>
                  <th>群組</th>
                  <th>聯絡窗口</th>
                  <th>聯絡電話</th>
                  <th>Email</th>
                </tr>
                <tr>
                  <td data-th="機關名稱" class="tL">士林區行政中心</td>
                  <td data-th="群組">成員</td>
                  <td data-th="聯絡窗口">趙金鳳</td>
                  <td data-th="聯絡電話" class="tL">28826200 #8702</td>
                  <td data-th="Email" class="tL">bn_459@mail.taipei.gov.tw</td>
                </tr>
                <tr>
                  <td data-th="機關名稱" class="tL">天文科學教育館</td>
                  <td data-th="群組">成員</td>
                  <td data-th="聯絡窗口">鄭元平</td>
                  <td data-th="聯絡電話" class="tL">28314551 #520</td>
                  <td data-th="Email" class="tL">yuanping.tam@gmail.com</td>
                </tr>
                <tr>
                  <td data-th="機關名稱" class="tL">士林分局</td>
                  <td data-th="群組">成員</td>
                  <td data-th="聯絡窗口">吳偉政</td>
                  <td data-th="聯絡電話" class="tL">0972710960</td>
                  <td data-th="Email" class="tL">SLE1200@police.taipei</td>
                </tr>
                <tr>
                  <td data-th="機關名稱" class="tL">士林運動中心</td>
                  <td data-th="群組">成員</td>
                  <td data-th="聯絡窗口">曾威勝</td>
                  <td data-th="聯絡電話" class="tL">28806066 #104</td>
                  <td data-th="Email" class="tL">wilsonpipi@gmail.com</td>
                </tr>
                <tr>
                  <td data-th="機關名稱" class="tL">士林市場</td>
                  <td data-th="群組">成員</td>
                  <td data-th="聯絡窗口">王主任</td>
                  <td data-th="聯絡電話" class="tL">0911615383</td>
                  <td data-th="Email" class="tL">frank62009@gmail.com</td>
                </tr>
                <tr>
                  <td data-th="機關名稱" class="tL">士東市場</td>
                  <td data-th="群組">代表戶</td>
                  <td data-th="聯絡窗口">何慶豐</td>
                  <td data-th="聯絡電話" class="tL">28345308</td>
                  <td data-th="Email" class="tL">majung6540@gmail.com</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  footer -->
    <footer>
      <div class="pagesize">
        <div class="footer-info">
          <div class="info-box info-main">
            <div class="logo"></div>
            <div class="copyright">
              <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
              <p>Copyright © All Rights Reserved</p>
            </div>
          </div>
          <div class="info-box info-sub">
            <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
            <div class="logo02"></div>
          </div>
        </div>
      </div>
    </footer>
    </div>
</template>

<style scoped>
    @import './css/inner.css';
</style>

<script>
import importJQ from './js/main'
import { mapMutations } from 'vuex'
import { DateTime } from 'luxon'
export default {
  data () {
    return {
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss')
    }
  },
  methods: {
    ...mapMutations([
      'COUNTING_TIMER',
      'LOGIN_OUT'
    ])
  },
  mounted () {
    importJQ()
  },
  computed: {

  }
}
</script>
